import { DatasourceObjectPosition } from '~/constants/types/storyblok';

export const checkIfVideoFile = (fileName: string) => {
  const fileExt = fileName.split('.').pop() || '';
  const videoExtensions = ['webm', 'mpg', 'mpeg', 'mpe', 'mp4', 'm4p', 'm4v', 'avi', 'wmv', 'mov'];
  return videoExtensions.includes(fileExt);
};

export const convertObjectPositionToBgPosition = (input: DatasourceObjectPosition) => {
  if (input === '') {
    return 'center';
  }
  return input.replace('object-', '').replaceAll('-', ' ');
};

/**
 * Uses storyblok image cdn to scale image
 * Used for background images
 */
export const storyblokScaleImage = (input: string, width: number) => {
  input = input.replace('a.storyblok.com', 'img2.storyblok.com');
  const split = input.split('/f/');
  // output must be url encoded %28  %29 = ( )
  const settings = `/${width * 2}x0/filters:format%28webp%29`;
  return `${split[0]}${settings}/f/${split[1]}`;
};
